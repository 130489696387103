<template>
  <div>
    <!--导航栏 全部/启用/禁用/异常-->
    <div class="navigatorInvoice">
      <router-link :to="{name:'ActivityInvoice'}">
      <div
          style="border-right:none"
          @click="activeNav(1)"
          class="nav-span"
          :class="{'active':active===1}"
      >{{ $t("ActivityInvoice") }}</div>
      </router-link>
      <router-link :to="{name:'MembershipInvoice'}">
      <div
          style="border-right:none"
          class="nav-span"
          @click="activeNav(2)"
          :class="{'active':active===2}"
      >{{ $t("MembershipInvoice") }}</div>
      </router-link>
      <router-link :to="{name:'DonationInvoice'}">
      <div
          class="nav-span"
          @click="activeNav(3)"
          :class="{'active':active===3}"
      >{{ $t("DonationInvoice") }}</div>
      </router-link>
    </div>
    
    <router-view></router-view>
  
  
    <!--详情弹窗-->
    <el-dialog
        :close-on-click-modal="false"
        :title=" $t('Detail') "
        :visible.sync="detailDialog"
        append-to-body
        center
        :show-close="false"
    >
      <el-form
          v-if="detailDialog"
          class="invoice-info"
      >
        <!--公司名称-->
        <el-form-item
            :label="companyName"
        >
          <div class="info">{{dialogDetail.companyName}}</div>
        </el-form-item>
        <!--公司地址-->
        <el-form-item
            :label="address"
        >
          <div class="info">{{dialogDetail.address}}</div>
        </el-form-item>
        <!--申请人-->
        <el-form-item
            :label="applyPeople"
        >
          <div class="info">{{dialogDetail.realName}}</div>
        </el-form-item>
        <!--Email-->
        <el-form-item
            label="Email"
        >
          <div class="info">{{dialogDetail.email}}</div>
          <!--<div class="info">{{dialogDetail.id}}</div>-->
        </el-form-item>
        <el-form-item
          :label="$t('Note')"
          prop="messageText"
          v-if="dialogDetail.comment"
          style="grid-column-start: 1;grid-column-end: 3;"
      >
          <div class="info" style="height: fit-content">
            {{dialogDetail.comment}}
          </div>
        </el-form-item>
      </el-form>
      <div
          slot="footer"
          class="dialog-footer"
          v-if="detailDialog"
      >
        <el-button @click="detailDialog = false">{{ $t("Confirm") }}</el-button>
        <el-button type="primary" :loading="loading" @click="markAsInvoiced(dialogDetail)">{{ $t("Invoiced") }}</el-button>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import bus from '@/assets/js/bus'
import api from "@/fetch/api";
import mixin from "@/assets/js/mixin"

export default {
  mixins:[mixin],
  name: 'InvoiceRequest',
  components: {
  },
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      num:3,
      adminId: data.id,
      active:1,
      detailDialog:false,
      dialogDetail:{},
      loading:false,
      companyName:this.$t('CompanyName'),
      address:this.$t('CompanyAddress'),
      applyPeople:this.$t('ApplyPeople'),
      right:Number(data.financePermission)
    };
  },
  created() {
    bus.$off('openDialog')
    bus.$on("openDialog", (val)=>{
      this.detailDialog = val.dialog
      this.dialogDetail = val
    });
    bus.$off('setInvoiced')
    bus.$on("setInvoiced", (val)=>{
      this.markAsInvoiced(val)
    });
    if(this.active===1) this.$router.push({name:'ActivityInvoice'})
    if(this.active===2) this.$router.push({name:'MembershipInvoice'})
    if(this.active===3){
      this.$router.push({name:'DonationInvoice'})
      this.companyName = this.$t('DonationUnit')
      this.address = this.$t('Address')
      this.applyPeople = this.$t('DonationPerson')
      this.$router.push({name:'DonationInvoice'})
    }
  },
  mounted() {},
  watch: {
  },
  methods: {
    activeNav(num){
      this.active = num
      if(this.active===3){
        this.companyName = this.$t('DonationUnit')
        this.address = this.$t('Address')
        this.applyPeople = this.$t('DonationPerson')
        this.dialogDetail.msg = true
      }else{
        this.companyName = this.$t('CompanyName')
        this.address = this.$t('CompanyAddress')
        this.applyPeople = this.$t('ApplyPeople')
        this.dialogDetail.msg = false
      }
    },
    
  //   标为已开发票   invoiceType   活动6 会员7 捐款8
    markAsInvoiced(data){
      if(this.right<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        let param = {
          currentAdmin:this.adminId,
          invoiceType:this.active+5,
          sourceId:data.id
        }
        api.setInvoiced(param).then(res=>{
          if(res.code === 200){
            this.$message.success(this.$t('SetInvoiced'))
            bus.$emit('freshPage')
            this.detailDialog = false
          }else{
            this.$message.error(this.$t('SysBusy'))
          }
        })
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.navigatorInvoice{
  width: 99%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  //display: flex;
  //flex-flow: row nowrap;
  //justify-content: space-evenly;
  text-align: center;
  margin: 20px auto;
  
  .nav-span{
    width: 100%;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    border: 1px solid black;
  }
  a{
    text-decoration: none;
    color: black;
  }
}
.active{
  background-color: #677d95;
  color: white;
}
.invoice-info{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: 20px;
  
  .info{
    margin-top: 40px;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    padding: 0 15px;
  }
}
</style>